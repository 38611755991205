import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { usePaginationParams } from 'utils/hooks/usePaginationParams';
import MaterialTableNew, { MaterialTableNewProps } from '../MaterialTableNew/MaterialTableNew';

type PaginatedTableWithQueryLocaledProps<T extends { locale: string }> = {
  queryKey: unknown[];
  queryFn(params: any): Promise<{ data: T[]; total: number }>;
  localesOptions: string[];
} & Omit<
  MaterialTableNewProps<T>,
  | 'data'
  | 'loading'
  | 'rowCount'
  | 'onPageSizeChange'
  | 'onPageChange'
  | 'order'
  | 'orderBy'
  | 'filters'
  | 'onRequestSort'
  | 'onFilterChange'
>;

const PaginatedTableWithQueryLocaled = <T extends { locale: string }>({
  queryFn,
  queryKey,
  localesOptions,
  ...tableProps
}: PaginatedTableWithQueryLocaledProps<T>) => {
  const { apiParams, ...params } = usePaginationParams<T>(tableProps.type);

  const [selectedLocale, setSelectedLocale] = useState(localesOptions[0]);
  
  useEffect(() => {
    if (localesOptions.length > 0) {
      const defaultLocale = localesOptions[0];
      if (!params.filters.locale?.value) {
        params.setFilters(prevFilters => ({
          ...prevFilters,
          locale: { value: defaultLocale }
        }));
        setSelectedLocale(defaultLocale);
      }
    }
  }, [localesOptions, params]);

  const { isLoading, data: result, isFetching } = useQuery(
    [...queryKey, apiParams],
    () => queryFn(apiParams),
    { keepPreviousData: true, refetchOnMount: 'always' }
  );

  console.log('Selected Locale:', selectedLocale);

  const filteredData = (result?.data || []).filter(item => localesOptions.includes(item.locale));

  return (
    <MaterialTableNew
      data={filteredData || []}
      loading={isLoading || isFetching}
      rowCount={result?.total || 0} //fix
      onPageSizeChange={params.setLimit}
      onPageChange={params.setPage}
      order={params.order}
      orderBy={params.orderBy}
      filters={params.filters}
      onRequestSort={params.toggleOrderBy}
      onFilterChange={debounce(params.setFilters, 1000)}
      {...tableProps}
    />
  );
};

export default PaginatedTableWithQueryLocaled;
