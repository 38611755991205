import * as React from "react";
import PropTypes from "prop-types";

// redux
import { withTranslate } from "components/hocs";

const StringInterpolatingField = ({
  translate,
  localeOverride, // for example, when we want to use activity's locale
  onBlur, // when the user finishes typing and switches their focus
  labelStringNumber,
  labelClassName,
  inputClassName,
  dispatch, // to avoid passing to input
  ...props
}) => {
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    // If locale override has changed (e.g. activity locale),
    // Then we should translate and emulate blur.
    if (!localeOverride) return;

    inputRef.current.focus();
    inputRef.current.blur();

  }, [localeOverride]);

  return (
    <>
      <label className={labelClassName}>
        {translate(labelStringNumber, "Text with string value")}
      </label>
      <input
        className={inputClassName}
        ref={inputRef}
        type="text"
        onBlur={(event) => {
          const pristine = event.target.value;
          const interpolated = pristine.replace(/#\d+/g, function (match) {
            return translate(
              match.slice(1),
              "Unknown",
              true,
              false,
              localeOverride
            );
          });
          interpolated && onBlur(interpolated);
        }}
        {...props}
      />
    </>
  );
}

StringInterpolatingField.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  localeOverride: PropTypes.string,
  // visual
  labelStringNumber: PropTypes.number.isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
}

export default withTranslate(StringInterpolatingField);
