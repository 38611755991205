import API from 'api';
import { axiosAPI, withLoading } from 'utils';

export const fetchActivityGroups = params => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .get('api/flink-make/activity-groups', { params })
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const getGroupElementsById = id => dispatch =>
  withLoading(dispatch, () => API.activityGroups.getGroupElementsById(id));

export const checkIfGroupsCanBeAdded = (...args) => dispatch =>
  withLoading(dispatch, () =>
    API.activityGroups.checkIfGroupsCanBeAdded(...args)
  );

export const addActivityGroup = data => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .post('api/flink-make/activity-groups', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const editActivityGroup = data => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .put('api/flink-make/activity-groups', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const deleteActivityGroup = id => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .delete(`api/flink-make/activity-groups/${id}`)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );
  export const deleteWordGroupFiles = files => dispatch =>
    withLoading(dispatch, () => API.storage.deleteFiles(files));
  
  export const moveToWordAgList = wordAgIds => dispatch =>
    withLoading(dispatch, async () =>
      axiosAPI
        .post('/api/flink-make/activity-groups/move-to-word-ag-list', { wordAgIds })
        .then(res => {
          if (!res.data.success) {
            return console.log(res.data);
          }
  
          return true;
        })
        .catch(err => {
          console.log(err);
          return false;
        })
    );
  
  export const removeFromWordAgList = wordAgIds => dispatch =>
    withLoading(dispatch, async () =>
      axiosAPI
        .post('/api/flink-make/activity-groups/remove-from-word-ag-list', { wordAgIds })
        .then(res => {
          if (!res.data.success) {
            return console.log(res.data);
          }
  
          return true;
        })
        .catch(err => {
          console.log(err);
          return false;
        })
    );
  