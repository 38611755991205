import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { imagesURL } from 'config';

const ActionsPanel = ({ buttons, vertical }) => {
  return (
    <StyledList vertical={vertical}>
      {buttons.map(btn => (
        <StyledBtn
          hide={btn.dontShow}
          key={btn.title}
          disabled={btn.disabled}
          onClick={btn.handler}
          title={btn.title}
        >
          <img  src={`${imagesURL}/FlinkMake/${btn.icon}`} alt={btn.title} />
        </StyledBtn>
      ))}
    </StyledList>
  );
};

ActionsPanel.propTypes = {
  buttons: PropTypes.array.isRequired
};

export default ActionsPanel;

const StyledList = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')}
  justify-content: space-between;
  margin-bottom: 5px;
  flex-shrink: 0;
`;

const StyledBtn = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 5px;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')}
  max-width: 45px;

  img {
    display: block;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:active {
    transform: scale(0.95);
  }

  /* &:last-child:not(:first-child) {
    margin-left: auto;
  } */
`;
