import * as React from "react";
// import API from "api";

// config & utils
// import { publicKey } from "config";
import { withLoading } from "utils";

// react select
import Select, { createFilter } from "react-select";

// Prop types
import PropTypes from "prop-types";

// redux
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
// import { withTranslate } from "components/hocs";

// classes
import classes from "./StringAssignField.module.scss";

// lodash
import _ from "lodash";

const StringAssignField = ({
  editingActivity,
  // contentFolder,
  // translate,
  strings,
  // locales,
  // User props
  label,
  // audio,
  value,
  // isTooltip,
  // forHelp,
  localeOverride,
  withoutAudio,
  // handlers
  changeAudioHandler,
  changeTextHandler,
  changeAllHandler,
  labelClassName
}) => {
  const dispatch = useDispatch();
  const locale = React.useMemo(() => localeOverride || editingActivity?.activity.locale || "en", [
    localeOverride,
    editingActivity?.activity.locale
  ]);

  const memoizedStrings = React.useMemo(() => {
    console.debug('Current locale is', locale);
    return _.chain(strings)
      // .filter(string => string.locale === locale)
      .orderBy(({ stringNumber }) => Number(stringNumber), ['asc'])
      .map(({ stringNumber, data, _id }) => ({
        label: `${stringNumber} - ${data[locale]?.stringValue}`,
        value: { stringNumber, _id },
      }))
      .value();
  }, [strings, locale]);

  const onChange = (option) => withLoading(dispatch, async () => {
    const string = +option?.value.stringNumber || undefined;
    const audio = (!withoutAudio && string) ? 'INHERITED' : '';

    console.debug('Should set string number', string);

    changeTextHandler?.(string);
    changeAudioHandler?.(audio);

    changeAllHandler?.({
      text: string,
      audio: audio,
    });
  });
  
  const selectValue = React.useMemo(() => {
    console.log('Current string number', value);
    return memoizedStrings.find(s => s.value.stringNumber === value);
  }, [memoizedStrings, value]); 

  return (
    <>
      {label && (
        <label className={labelClassName}>
          {label}
        </label>
      )}
      <Select
        isSearchable
        isClearable
        className={classes.stringAssignField}
        filterOption={createFilter({ ignoreAccents: false })}
        value={selectValue ?? null}
        onChange={onChange}
        options={memoizedStrings}
        textFieldProps={{
          label: 'Label',
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
    </>
  );
}

StringAssignField.propTypes = {
  // translate: PropTypes.func.isRequired,
  strings: PropTypes.array.isRequired,
  // locales: PropTypes.array.isRequired,
  editingActivity: PropTypes.object,
  // contentFolder: PropTypes.string,
  // value
  label: PropTypes.string,
  // audio: PropTypes.any, // current audio file
  value: PropTypes.any,
  // isTooltip: PropTypes.bool,
  // forHelp: PropTypes.bool,
  localeOverride: PropTypes.string,
  withoutAudio: PropTypes.bool,
  // handlers
  changeAudioHandler: PropTypes.func,
  changeTextHandler: PropTypes.func,
  changeAllHandler: PropTypes.func,
  labelClassName: PropTypes.string,
}

const mapStateToProps = ({ common, flinkMake }) => ({
  strings: common.strings,
  // locales: common.locales,
  editingActivity: flinkMake.activity.editingActivity,
  // contentFolder: flinkMake.activity.editingActivity?.activity.contentFolder,
});

export default compose(
  // withTranslate,
  connect(mapStateToProps),
)(StringAssignField);
